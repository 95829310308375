import React, { Component } from 'react';
import {EditButton, DeleteButton, CancelButton, PlusButton, ValidateButton, FolderButton, ExportButton, DuplicateButton} from '../Buttons/RoundButtons'
import './toolbar.scss'
import {Download} from '../lib/csvDownload'

const ToolBar = (props) => {
  let Buttons = "";

  switch (props.state) {
    case "all":
      return (
        <div className = "toolbar">
          <div className = "toolbar__button-container">
            <button className="grey" onClick = {(e) => props.changeStateTo(e, "add")}>
              <PlusButton/><span>Ajouter un Panneau</span>
            </button>
            <button onClick = {(e) => props.changeStateTo(e, "archive")}>
              <FolderButton/>
              <span>Archives</span>
            </button>
            <Download data={props.data} fields = {props.fields} profile = {props.profile} />
          </div>
        </div>
      )
      break;
    case "add":
      return (<div className = "toolbar">
          <div className = "toolbar__button-container">
            <button onClick = {(e) => props.changeStateTo(e, "all")}>
              <CancelButton/><span>Annuler</span>
            </button>
            <button onClick = {props.saveItemInDB}>
              <ValidateButton/><span>Enregistrer</span>
            </button>
          </div>
        </div>)
      break;
    case "select":
      return (<div className = "toolbar">
        <div className = "toolbar__button-container">
          <button onClick = {props.archiveItemInDB}>
            <FolderButton/><span>Archiver</span>
          </button>
          <button onClick = {(e) => props.changeStateTo(e, "edit")}>
            <EditButton/><span>Modifier</span>
          </button>
          <button onClick = {(e) => props.changeStateTo(e, "all")}>
            <CancelButton/><span>Annuler Selection</span>
          </button>
          <button onClick = {props.deleteItemInDB}>
            <DeleteButton/><span>SUPPRIMER !</span>
          </button>
          <button onClick = {props.duplicateItemInDB}>
            <DuplicateButton/><span>Dupliquer</span>
          </button>
        </div>
      </div>)
      break;
    case "archive":
      return (<div className = "toolbar">
        <span><b>Archives</b></span>

        <div className = "toolbar__button-container">
          <button onClick = {(e) => props.changeStateTo(e, "all")}>
            <CancelButton/><span>Retour</span>
          </button>
          <Download data={props.data} fields = {props.fields} profile = {props.profile} />
        </div>
      </div>)
      break;
    case "selectarchive":
      return (<div className = "toolbar">
        <div className = "toolbar__button-container">
          <button onClick = {props.unarchiveItemInDB}>
            <FolderButton/><span>Desarchiver</span>
          </button>
          <button onClick = {(e) => props.changeStateTo(e, "all")}>
            <CancelButton/><span>Annuler Selection</span>
          </button>
          <button onClick = {props.deleteItemInDB}>
            <DeleteButton/><span>SUPPRIMER !</span>
          </button>
        </div>
      </div>)
      break;
    case "edit":
      return (<div className = "toolbar">
          <div className = "toolbar__button-container">
            <button onClick = {(e) => props.changeStateTo(e, "all")}>
              <CancelButton/><span>Annuler</span>
            </button>
            <button onClick = {props.changeItemInDB}>
              <ValidateButton/><span>Enregistrer</span>
            </button>
          </div>
        </div>)
      break;
  }
}


export default ToolBar

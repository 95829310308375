import React from 'react';
import { Redirect, Route, Router } from 'react-router-dom';
import App from './App';
import Callback from './Callback/Callback';
import Auth from './Auth/Auth';
import Client from './Client/Client';
import Commercial from './Commercial/Commercial';
import Admin from './Admin/Admin';
import history from './history';
import ConnectionList from './connection-list/ConnectionList';

const auth = new Auth();

const handleAuthentication = ({location}) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
}

function isAllowedToSeeConnectionList() {
  // TODO filter users based on their privileges
  return true
}

export const makeMainRoutes = () => {
  return (
      <Router history={history}>
        <div>
          <Route exact path="/" render={(props) => <App auth={auth} {...props} />} />
          <Route path="/callback" render={(props) => {
            handleAuthentication(props);
            return <Callback {...props} />
          }}/>
          <Route path="/client" render={(props) => (
            !auth.isAuthenticated() ? (
              <Redirect to="/"/>
            ) : (
              <Client auth={auth} {...props} />
            )
          )} />
          <Route path="/commercial" render={(props) => (
            !auth.isAuthenticated() || !auth.userHasScopes(['read:panneaux']) ? (
              <Redirect to="/"/>
            ) : (
              <Commercial auth={auth} {...props} />
            )
          )} />
          <Route path="/admin" render={(props) => (
            !auth.isAuthenticated() || !auth.userHasScopes(['write:panneaux'])? (
              <Redirect to="/"/>
            ) : (
              <Admin auth={auth} {...props} />
            )
          )} />
          <Route path="/connection-list" render={(props) => (
            isAllowedToSeeConnectionList() ? (
              <ConnectionList auth={auth} {...props} />
            ) : (
              <Redirect to="/"/>
            )
          )} />
        </div>
      </Router>
  );
}

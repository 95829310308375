import React, { Component } from 'react';
import fields from '../data/billboards.json'
import {dateToReadableString} from '../lib/date'
import './tableau.scss'
import {pdfFichePose, getDataUri} from '../lib/pdf'
import {DownloadIcon} from '../icons/pdficon.js'
import {WaitIcon} from '../icons/waiticon.js'
import {DoneIcon} from '../icons/doneicon.js'

const CLD_URL = 'https://res.cloudinary.com/dqi1btegb/image/upload';

class Row extends Component {
  constructor (props) {
    super(props);
    this.state = {
      icon:"download",
      isSelected:false
    }
    this.makePdf = this.makePdf.bind(this);
    this.handleSelect = this.handleSelect.bind(this)
  }

  componentDidMount(){
    const isSelected = this.props.selectedId === this.props.data._id ? true : false;
    this.setState({isSelected})
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.isSelected === nextState.isSelected && this.props.data === nextProps.data) {
      if (this.state.isSelected && nextProps.selectedId !== this.props.data._id) {
        this.setState({isSelected : false})
        return true
      }
      return false;
    } else {
      return true;
    }
  }

  makePdf(){
    this.setState({icon:"wait"})
    pdfFichePose(this.props.data)
      .then(() => this.setState({icon:"done"}))
  }

  handleSelect(){
    this.setState({isSelected:true})
    this.props.changeStateToSelect(this.props.data._id);
  }

  render () {
    const {data, index} = this.props;
    const contratfournisseur = data.contratfournisseur ? "Oui" : "Non";
    const facturefournisseur = data.facturefournisseur ? "Oui" : "Non";
    const facturepa = data.facturepa ? 'Oui' : "Non";
    const asurveiller = data.aSurveiller ? 'Oui' : "Non";
    const nouvellepose = data.nouvellepose ? 'Oui' : "Non";
    let classToAdd = this.state.isSelected ? "row-selected" : "";
    classToAdd = data.aSurveiller ? "row-red" : classToAdd;
    classToAdd = data.nouvellePose ? "row-purple" : classToAdd;

    let Icon = <DownloadIcon/>
    switch (this.state.icon) {
      case "download":
        Icon = <DownloadIcon/>
        break;
      case "wait":
        Icon = <WaitIcon/>
        break;
      case "done":
        Icon = <DoneIcon/>
        break;
      default:
        Icon = <DownloadIcon/>
    };
    const tdData = fields.map(field => {
      let cell = "";
      switch (field.type) {
        case "number":
          let n = data[field.name] ? data[field.name] : 0;
          if (field.format === "%") {
            cell = Math.round(n*100)/100 + "%"
          } else if (field.format === "€") {
            if (field.name === "pdv") {
              cell = n.toLocaleString('fr', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + " "+data["devise"]
            } else if (field.name === "dpaNet") {
              cell = data["deviseDPA"] ? n.toLocaleString('fr', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + " "+data["deviseDPA"] : "-"
            } else {
              cell = n.toLocaleString('fr', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + " €"
            }
          } else {
            cell = n
          }
          break;
        case "text":
          if(field.name === "photoSrc") {
            // cell = data.photoSrc.length > 0 ? <button className = "button-download center" onClick = {this.makePdf}>{Icon}</button> : "";
            cell = data.photoSrc.length > 0 ? <a href={CLD_URL + "/"+data.photoSrc[0]} target="_blank"><button className = "button-download center">{Icon}</button></a> : ""
          } else if(field.name === "bailPdf") {
            cell = data.bailPdf ? <a href={CLD_URL + "/"+data.bailPdf} target="_blank"><button className = "button-download center">{Icon}</button></a> : ""
          } else {
            cell = data[field.name]
          }
          break;
        case "date":
          cell = data[field.name] ? dateToReadableString(data[field.name]) : ""
          break;
        case "checkbox":
          cell = data[field.name] ? <div className = "square square-true"></div> : <div className = "square"></div>;
          break;
        default:
          cell = data[field.name]
      }
      if (field.name ==="client" || field.name === "reference" || field.name === "devise" || field.name === "deviseDPA") {
        return false
      }

      return (
        <td
          key = {field.name}
          className = {field.cellFormat + " "+classToAdd}>{cell}
        </td>
      )
    })

    return (
      <tr key = {data._id} id = {data._id}>
        <th
          className={"headcol-index headcol__index"+classToAdd}
          onClick = {this.handleSelect}
          id = {data._id}>
            <span className="index">{index+1}</span>
        </th>
        <th
          className={"headcol headcol__client"+classToAdd}
          onClick = {this.handleSelect}
          id = {data._id}>
            {data.client}
        </th>
        <th
          className={"headcol headcol__reference"+classToAdd}
          onClick = {this.handleSelect}
          id = {data._id}>
            {data.reference}
        </th>
        {tdData}
      </tr>
    )
  }
}

class Tableau extends Component {
  constructor (props){
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.panneaux === nextProps.panneaux && this.props.selectedId === nextProps.selectedId) {
      return false;
    } else {
      return true;
    }
  }

  componentDidMount(){
    setTimeout( () => {
      const section = document.querySelector( '.row-selected' );
      if (section) {
         section.scrollIntoView( { behavior: 'smooth', block: 'center' } );
      }
    }, 1000) // idee sale de Mathieu mais ça marche

  }

  render(){
    const {panneaux, changeStateToSelect} = this.props;
    const rows = panneaux.map((panneau, index) =>
      <Row
        key = {panneau._id}
        data = {panneau}
        changeStateToSelect = {changeStateToSelect}
        index = {index}
        selectedId = {this.props.selectedId}
      />);

    return (
      <div className = "panneaux" id = "tableau">
        <table>
          <thead>
            <Headers sort = {this.props.sort}/>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    )
  }
}
export default Tableau

class Headers extends Component {
  constructor (props) {
    super(props);
  }

  render(){
    const {sort} = this.props
    const thLabels = fields.map(field => {
      if (field.name ==="client" || field.name === "reference" || field.name === "devise" || field.name === "deviseDPA") {
        return false
      }
      return (
        <th key = {"header"+field.name}>
          <SortButton
            sort = {sort}
            keyToSort = {field.name}
            text = {field.label}
          />
        </th>
      )
    })

    return (
      <tr>
        <th className="headcol-index headcol__index"></th>
        <th className="headcol headcol__client">
          <SortButton
            sort = {sort}
            keyToSort = "client"
            text = "Client"
          />
        </th>
        <th className="headcol headcol__reference">
          <SortButton
            sort = {sort}
            keyToSort = "reference"
            text = "Référence"
          />
        </th>
        {thLabels}
      </tr>
    )
  }
}

class SortButton extends Component {
  constructor (props) {
    super(props);
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(){
    this.props.sort(this.props.keyToSort)
  }

  render(){
    return (
      <button onClick = {this.handleClick}> {this.props.text} </button>
    )
  }
}

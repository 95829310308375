import React, { Component } from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import {dateToReadableString} from '../lib/date'

const CLD_URL = 'https://res.cloudinary.com/dqi1btegb/image/upload';

class MultipleMarkers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center : [ 46.349, 6.4433],
      zoom: 13
    }
  }
  render() {
    // const position = this.state.center;
    const height = document.body.clientHeight;
    const markersWithCoordinates = this.props.markers.filter(marker => {
      if (!isNaN(marker.position[1]) && !isNaN(marker.position[0]) ) return marker
    });
    const position = markersWithCoordinates.map(marker => marker.position);
    let border = [];
    if (position.length > 1) {
      border = [
        [Math.min(...position.map(pos => pos[0])), Math.min(...position.map(pos => pos[1]))],
        [Math.max(...position.map(pos => pos[0])), Math.max(...position.map(pos => pos[1]))]
      ]
    } else if (position.length === 1) {
      let latMax = parseFloat(position[0][0])+0.05;
      let lonMax = parseFloat(position[0][1])+0.05;
      let latMin = parseFloat(position[0][0])-0.05;
      let lonMin = parseFloat(position[0][1])-0.05;
      border = [[latMin, lonMin], [latMax, lonMax]]
    } else {
      border = [[50.505, -29.09], [52.505, 29.09]]
    }
    const markers = markersWithCoordinates.map(marker => {
      let icon = marker.data.fournisseur ? markerPanneauxFournisseurs : markerPanneauxBailleurs
      return (
        <Marker position={marker.position} key={marker.data.reference} icon={icon}>
          <Popup>
            <InsidePopup data = {marker.data} openMore = {this.props.openMore}/>
          </Popup>
        </Marker>
      )

    })
    const shops = this.props.shops ? this.props.shops : [];
    const carrefourShops = shops.filter((shop) => shop.type === "carrefour");
    const isMarkersProvencia = this.props.showMagasinsProvencia ? <MarkersProvencia shops={carrefourShops} /> : "";
    const soBioShops = shops.filter((shop) => shop.type === "soBio");
    const isMarkersSoBio = this.props.showMagasinsSoBio ? <MarkersSoBio shops={soBioShops} /> : "";
    const brutButcherShops = shops.filter((shop) => shop.type === "brutButcher");
    const isMarkersBrutButcher = this.props.showMagasinsBrutButcher ? <MarkersBrutButcher shops={brutButcherShops} /> : "";
    return (
      <Map bounds={border} style={{height:height}}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {markers}
        {isMarkersProvencia}
        {isMarkersSoBio}
        {isMarkersBrutButcher}
      </Map>
    )
  }
}

class InsidePopup extends Component {
  constructor (props) {
    super(props);
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(){
    this.props.openMore(this.props.data._id)
  }
  render(){
    const {ville, adresse, format, dateRenouvellement, reference, photoSrc, client} = this.props.data;
    const imgs = photoSrc.map(photo => {return (<img src = {`${CLD_URL}/c_scale,w_300/${photo}.jpg`} key={photo}></img>)})
    const button = this.props.openMore ? <button onClick = {this.handleClick} className = "button-primary button-primary--small">Voir Plus ... </button> : ""
    return (
      <div className = "map__popup">
        {imgs}
        <h3>{ville}</h3>
        <h4>{client}</h4>
        <p>{adresse}</p>
        <p><b>Réf : </b>{reference}</p>
        <p><b>Format : </b>{format}</p>
        <p><b>Réservé jusqu'au : </b>{dateToReadableString(dateRenouvellement)}</p>
        {button}
      </div>
    )
  }
}

const MarkersProvencia = (props) => {
  const markers = props.shops.map(marker => {
    return (
      <Marker position={[marker.lat,marker.lon]} key={marker.magasin} icon = {provenciaPoint}>
        <Popup>
          {marker.magasin}
        </Popup>
      </Marker>
    )
  })
  return markers
}

const MarkersSoBio = (props) => {
  const markers = props.shops.map(marker => {
    return (
      <Marker position={[marker.lat,marker.lon]} key={marker.magasin} icon = {soBioPoint}>
        <Popup>
          {marker.magasin}
        </Popup>
      </Marker>
    )
  })
  return markers
}

const MarkersBrutButcher = (props) => {
  const markers = props.shops.map(marker => {
    return (
      <Marker position={[marker.lat,marker.lon]} key={marker.magasin} icon = {brutButcherPoint}>
        <Popup>
          {marker.magasin}
        </Popup>
      </Marker>
    )
  })
  return markers
}

const provenciaPoint = new L.Icon({
  iconUrl: 'https://res.cloudinary.com/dqi1btegb/image/upload/v1575723701/clients/marker-provencia_txihth.png',
  iconRetinaUrl: 'https://res.cloudinary.com/dqi1btegb/image/upload/v1575723701/clients/marker-provencia_txihth.png',
  iconAnchor: [20, 10],
  popupAnchor: [0, -35],
  iconSize: [15, 20],
  shadowSize: [20, 20],
  shadowAnchor: [7, 40],
})

const soBioPoint = new L.Icon({
  iconUrl: 'https://res.cloudinary.com/dqi1btegb/image/upload/v1630850722/clients/marker-sobio_wtfcuw.png',
  iconRetinaUrl: 'https://res.cloudinary.com/dqi1btegb/image/upload/v1630850722/clients/marker-sobio_wtfcuw.png',
  iconAnchor: [20, 10],
  popupAnchor: [0, -35],
  iconSize: [15, 20],
  shadowSize: [20, 20],
  shadowAnchor: [7, 40],
})

const brutButcherPoint = new L.Icon({
  iconUrl: "https://res.cloudinary.com/dqi1btegb/image/upload/v1703863975/clients/LogoBB-CG_cuzugw.png",
  iconRetinaUrl: "https://res.cloudinary.com/dqi1btegb/image/upload/v1703863975/clients/LogoBB-CG_cuzugw.png",
  iconAnchor: [20, 10],
  popupAnchor: [0, -35],
  iconSize: [15, 20],
  shadowSize: [20, 20],
  shadowAnchor: [7, 40],
})

const markerPanneauxBailleurs = new L.Icon({
  iconUrl:'https://res.cloudinary.com/dqi1btegb/image/upload/v1598713168/marker-panneaux-autre_xs9qht.png',
  iconRetinaUrl:'https://res.cloudinary.com/dqi1btegb/image/upload/v1598713168/marker-panneaux-autre_xs9qht.png',
  iconAnchor: [12, 41],
  popupAnchor: [0, -35],
  iconSize: [25, 41]
})

const markerPanneauxFournisseurs = new L.Icon({
  iconUrl:'https://res.cloudinary.com/dqi1btegb/image/upload/v1598713554/marker-panneaux-fournisseurs_g9z39p.png',
  iconRetinaUrl:'https://res.cloudinary.com/dqi1btegb/image/upload/v1598713554/marker-panneaux-fournisseurs_g9z39p.png',
  iconAnchor: [12, 41],
  popupAnchor: [0, -35],
  iconSize: [25, 41]
})

export default MultipleMarkers

export const dateToReadableString = (date) => {
  const dateFormated = new Date(date)
  const mm = dateFormated.getMonth()+1;
  const dd = dateFormated.getDate();
  const yyyy = dateFormated.getFullYear()
  return [(dd>9 ? '' : '0') + dd,
          (mm>9 ? '' : '0') + mm,
          yyyy
        ].join('/');
}

export const dateForForm = (date) => {
  const dateFormated = new Date(date);
  const mm = dateFormated.getMonth()+1;
  const dd = dateFormated.getDate();
  const yyyy = dateFormated.getFullYear()
  return [yyyy,
    (mm>9 ? '' : '0') + mm,
    (dd>9 ? '' : '0') + dd
  ].join('-');
}

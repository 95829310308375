import React, { Component } from 'react';
import Profile from './Profile/Profile';
import Navbar from './Navbar';
import './index.scss'

class App extends Component {
  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  componentDidMount() {
    const { renewSession } = this.props.auth;
    if (localStorage.getItem('isLoggedIn') === 'true') {
      renewSession();
    }
  }

  login() {
    this.props.auth.login();
  }

  logout() {
    this.props.auth.logout();
  }

  render() {
    const { isAuthenticated, userHasScopes } = this.props.auth;
    let Action = <Home login = {this.login.bind(this)}/>;

    if (isAuthenticated() && userHasScopes(['write:panneaux'])) {
      Action = <button onClick={this.goTo.bind(this, 'admin')}> Gérer les Panneaux </button>
    } else if (isAuthenticated() && userHasScopes(['read:panneauxClient'])) {
      <button onClick={this.goTo.bind(this, 'client')}> Voir Mes Panneaux </button>
    } else if (isAuthenticated() && userHasScopes(['read:panneaux'])) {
      <button onClick={this.goTo.bind(this, 'commercial')}> Voir Les Panneaux </button>
    } else if (isAuthenticated()) {
      Action = <HomeLoginNotRecognized logout = {this.logout.bind(this)}/>  ;
    }
    return (
      <div>
        <div className="container">
          {Action}
        </div>
      </div>
    );
  }
}

export default App;

const Home = (props) => {
  return (
    <div className = "container container--home">
      <img src = "https://res.cloudinary.com/dqi1btegb/image/upload/v1589468760/CAP-GESTION-LOGO-0520_x5mpfc.png" alt = "logo CAP Gestion"></img>
      <h1> Bienvenue dans votre outil de gestion de panneaux publicitaires !</h1>
      <button onClick={props.login}>Connectez-vous à votre compte</button>
    </div>
  )
}

const HomeLoginNotRecognized = (props) => {
  return (
    <div className = "container container--home">
      <h1> Bienvenue !</h1>
      <img src = "https://res.cloudinary.com/dqi1btegb/image/upload/v1589468760/CAP-GESTION-LOGO-0520_x5mpfc.png" alt = "logo CAP Gestion"></img>
      <p>Vous êtes connecté mais n'avez aucune permission. Contacter contact@performance-adviser.com pour en savoir plus.</p>
      <button onClick={props.logout}>Se Deconnecter</button>
    </div>
  )
}

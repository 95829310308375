import React, { Component } from 'react';
import {dateToReadableString} from '../lib/date'
import jsPDF from 'jspdf'

const CLD_URL = 'https://res.cloudinary.com/dqi1btegb/image/upload';

export const pdfFichePose = (panneau) => {
  return new Promise((resolve, reject) => {
    const {photoSrc, format, reference, adresse, dateDepart, dateChangementVisuel, ville, gps} = panneau;
    let doc = new jsPDF('landscape');
    const pdfname = 'fiche-de-pose.pdf';
    const photoHeight = 500;
    const photoWidth = 660;
    const photoHeightCm = 180;
    const photoWidthCm = photoHeightCm * (photoWidth / photoHeight);
    getAllDataUri(photoSrc, photoHeight, photoWidth)
        .then(dataUri => {
          for (var i = 0; i < dataUri.length; i++) {
            doc.setDrawColor(0)
            doc.setFillColor(74, 74, 74)
            doc.rect(0, 0, 90, 25, 'F')
            doc.setFont('helvetica')
            doc.setFontSize(20)
            doc.setTextColor(255, 255, 255)
            doc.text(reference, 30, 15)
            doc.setTextColor(74, 74, 74)
            doc.setFontSize(30)
            doc.text(ville.toUpperCase(), 100, 17)
            doc.rect(0, 25, 297, 180, 'F')
            doc.setTextColor(255, 255, 255)
            doc.setFontSize(12)
            const ypostext = 40;
            const gap = 20;
            doc.text(`Format :`, photoWidthCm + 5, ypostext)
            doc.text(`${format}`, photoWidthCm + 5, ypostext+(gap/3))
            doc.text(`Adresse : `, photoWidthCm + 5, ypostext+gap)
            var adresseSplited = doc.splitTextToSize(`${adresse}`, 290 - photoWidthCm);
            doc.text(adresseSplited, photoWidthCm + 5, ypostext+gap+(gap/4));
            doc.text(`Date départ Contrat :`, photoWidthCm + 5, ypostext+(gap*2))
            doc.text(`${dateToReadableString(dateDepart)}`, photoWidthCm + 5, ypostext+(gap*2+(gap/3)))
            doc.text(`Changement de Visuel :`, photoWidthCm + 5, ypostext+(gap*3))
            doc.text(`${dateToReadableString(dateChangementVisuel)}`, photoWidthCm + 5, ypostext+(gap*3)+(gap/3))

            if (gps) {
              doc.text(`Coordonnées GPS :`, photoWidthCm + 5, ypostext+(gap*4));
              doc.text(`${gps}`, photoWidthCm + 5, ypostext+(gap*4+(gap/3)))
            }
            doc.addImage(dataUri[i], 'JPEG', 0, 25, photoWidthCm, photoHeightCm)
            doc.setFontSize(8)
            doc.setTextColor(74, 74, 74)
            doc.text("Performance Adviser - Tél. (+33) 450 17 00 50 - contact@performance-adviser.com - www.performance-adviser.com", 145, 208)
            if ( i < dataUri.length-1) {
              doc.addPage()
            }
          }
          doc.save(pdfname, {returnPromise : true}).then(() => {
            resolve()
          })
        })
  })
}


export const getDataUri = (url) => {
  return new Promise((resolve, reject) => {
    var image = new Image();
    image.crossOrigin = "Anonymous";
    image.src = url;
    image.onload = ()  => {
        var canvas = document.createElement('canvas');
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;
        canvas.getContext('2d').drawImage(image, 0, 0);
        // Get raw image data
        resolve(canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, ''))
    };
  })
}

const getAllDataUri = (photoSrc, photoHeight, photoWidth) => {
  return new Promise(function(resolve, reject) {
    var promises = photoSrc.map(src => {
      const url = `${CLD_URL}/c_fill,h_${photoHeight},w_${photoWidth}/${src}`;
      return getDataUri(url)
        .then(res => {
          return res
        })
    })

    Promise.all(promises).then(function(results) {
        resolve(results)
    })
  });


}

import React, { Component } from 'react';
import Navbar from '../Navbar';
import Filter from '../commons/Filter';
import Tableau from './Tableau';
import {dialogWithDB} from '../lib/communicationWithDB'
import {sortByMonth, sortByKey} from '../lib/sort'
import inputs from '../data/billboards.json'
import fields from '../data/billboards.json'
import { API_URL } from '../apiUrl.js';
import {dateForForm} from '../lib/date'
import ToolbarChoice from '../commons/ToolbarChoice';
import MultipleMarkers from '../commons/Map';
import FicheDePose from '../commons/FicheDePose';
import {csvDownload} from '../lib/csvDownload'

const ROUTE = '/admin/billboard';

class Commercial extends Component {
  constructor (props) {
    super(props);
    this.state = {
      profile: {},
      selectedId:"",
      isMapShowed : false,
      panneaux:[],
      allPanneaux:[],
      isFilterOpen: false,
      state:"all",
      filters: {
        client:[],
        ville:[],
        format:[],
        adresse:[],
        fournisseur:[],
        moisFact:[],
        atc:[]
      }
    }
    this.openFilter = this.openFilter.bind(this)
    this.filter = this.filter.bind(this)
    this.sort = this.sort.bind(this)
    this.toggleView = this.toggleView.bind(this)
    this.changeStateTo = this.changeStateTo.bind(this)
  }

  componentWillMount() {
    const { userProfile, getProfile } = this.props.auth;
    if (!userProfile) {
      getProfile((err, profile) => {
        this.setState({ profile });
      });
    } else {
      this.setState({ profile: userProfile });
    }
  }

  componentDidMount(){
    const { getAccessToken } = this.props.auth;
    dialogWithDB(`${API_URL}${ROUTE}`,'GET', "", getAccessToken)
      .then(response => {
        const panneauxSorted = sortByMonth(response.panneaux);
        this.setState({
          panneaux: panneauxSorted,
          allPanneaux : panneauxSorted
        })
      })
      .catch(error => alert(error.message) );
  }

  toggleView() {
    this.setState(prevState => ({
      isMapShowed: !prevState.isMapShowed
    }));
  }

  filter(key, value){
    let filters = this.state.filters;
    const index = filters[key].indexOf(value);
    if(index === -1) {
      filters[key].push(value);
    } else {
      filters[key].splice(index,1)
    }
    this.setState({filters});
    let panneaux = this.state.allPanneaux;
    Object.keys(filters).map(key => {
      if(filters[key].length>0){
        panneaux = panneaux.filter(panneau => (filters[key].indexOf(panneau[key])>-1))
      }
    })
    this.setState({panneaux})
  }

  openFilter(){
    this.setState(prevState => ({isFilterOpen : !prevState.isFilterOpen}))
  }

  sort(key){
    let panneauxSorted = sortByKey(this.state.panneaux.slice(0), key);
    this.setState({panneaux: panneauxSorted})
  }

  changeStateTo(e, state){
    const { getAccessToken } = this.props.auth;
    let newState = state;
    switch (state) {
      case "all":
        const panneauxSorted = sortByMonth(this.state.allPanneaux);
        this.setState({
          panneaux: panneauxSorted
        })
        break;
      case "archive":
        dialogWithDB(`${API_URL}${ROUTE}/archived`,'GET', "", getAccessToken)
          .then(response => {
            const panneauxSorted = sortByMonth(response.panneaux);
            this.setState({
              panneaux: panneauxSorted
            })
          })
          .catch(error => alert(error.message) );
        break;
    }

    this.setState({state: newState})
  }
  render() {
    const { profile, message, panneaux, isMapShowed} = this.state;
    let TableauPanneaux = "";
    const markers = panneaux.map(panneau => {
      const latitude = panneau.gps.split(", ")[0];
      const longitude = panneau.gps.split(", ")[1];
      return {
        data : panneau,
        position : [latitude, longitude]
      }
    })
    const styleContainerMap = {
      "height" : window.innerHeight-120
    }
    const fieldsToExport = fields.filter(field => field.name !== "photoSrc")

    return (
      <div>
        <Navbar
          auth = {this.props.auth}
          nickname = {profile.nickname}
          openFilter = {this.openFilter}
          isFilterOpen = {this.state.isFilterOpen}
          history={this.props.history}
          />

        <ToolbarChoice
          auth = {this.props.auth}
          toggleView = {this.toggleView}
          isMapShowed = {this.state.isMapShowed}
          data={panneaux}
          fields = {fieldsToExport}
          showArchive = {this.changeStateTo}
          state = {this.state.state}
          profile = {profile}
          />

        <Filter
          isFilterOpen = {this.state.isFilterOpen}
          panneaux = {this.state.allPanneaux}
          filter = {this.filter}
          filters = {this.state.filters}/>

        <div className="container" style = {styleContainerMap}>
          <Content
            markers = {markers}
            panneaux = {panneaux}
            isMapShowed = {isMapShowed}
            sort = {this.sort}
            />
        </div>
      </div>
    );
  }
}


const Content = (props) => {
  const {markers, panneaux, isMapShowed, billboardSelected, eraseSelection, sort} = props;
  if (isMapShowed) {
    return (
      <MultipleMarkers markers = {markers} />
    )
  } else {
    return (
      <Tableau
        panneaux = {panneaux}
        sort = {sort}
        />
    )
  }
}


export default Commercial;

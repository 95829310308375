import React from 'react'
import { Component } from 'react'
// server communication
import {dialogWithDB} from '../lib/communicationWithDB'
import { API_URL } from '../apiUrl.js';
const ROUTE = '/restricted/connection-list';
import {dateToReadableString} from '../lib/date'

import './connectionlist.scss'

export default class ConnectionList extends Component {

  constructor(props) {
    super(props)
    this.fetch = this.fetch.bind(this)
    this.goToHome = this.goToHome.bind(this)

    this.state = {connections: [], status: 'fetching', error: null}
  }

  goToHome() {
    this.props.history.replace('')
  }

  componentDidMount() {
    this.fetch()
  }

  fetch() {
    this.setState({status: 'fetching', error: null})
    const getAccessToken = this.props.auth.getAccessToken
    const url = `${API_URL}${ROUTE}`
    dialogWithDB(url, 'GET', '', getAccessToken).then((result) => {
      if (result.status === undefined) {
        this.setState({connections: result, status: 'fetched'})
      } else {
        throw result
      }
    })
    .catch((error) => {
      console.error(error)
      this.setState({status: 'failed', error})
    })
  }

  buildTable() {
    if (this.state.connections.length === 0) return '⚠️ Aucune connexion récente'

    const lines = this.state.connections.map((connection) => {
      const date = dateToReadableString(connection.date)
      return (
        <tr key={connection._id}>
          <td>{date}</td>
          <td>{connection.name}</td>
          <td>{connection.email}</td>
        </tr>
      )
    })
    return (
      <div className = "panneaux">
        <table className = "table_connection">
          <thead>
              <th>date</th>
              <th>name</th>
              <th>email</th>
          </thead>
          <tbody>
            {lines}
          </tbody>
        </table>
      </div>
    )
  }

  buildStatus() {
    if (this.state.status === 'fetched') return '✅'
    if (this.state.status === 'failed') return `❌ [${this.state.error.message}]`
    if (this.state.status === 'fetching') return '⏳'
  }

  render() {

    return (
      <div className="container__connection" >
        <button className="button__back" onClick = {this.goToHome}>
          Retour à la page principale
        </button>
        <h2>Liste des connexions</h2>
        <div>
          {this.buildStatus()}
        </div>
        {this.buildTable()}
        <hr></hr>
        <button className="button__refresh" onClick = {this.fetch}>Rafraichir la liste</button>
      </div>
    )
  }
}

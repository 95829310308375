import React, { Component } from 'react';
import {FilterButton, CancelBlueButton} from './Buttons/RoundButtons'
import {DeconnectButton, ConnectionList} from './Buttons/ConnectionsButtons'
import './navbar.scss'


class Navbar extends Component {
  componentDidMount() {
    const { renewSession, userProfile, getProfile } = this.props.auth;
    if (localStorage.getItem('isLoggedIn') === 'true') {
      renewSession();
    }
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  login() {
    this.props.auth.login();
  }

  logout() {
    this.props.auth.logout();
  }

  render() {
    const { isAuthenticated, userHasScopes } = this.props.auth;
    const {img, eraseSelection, openFilter, nickname, isFilterOpen} = this.props;
    const Button = isAuthenticated() ?
      <button onClick={this.logout.bind(this)}>
        <DeconnectButton/>
      </button> :
      <button onClick={this.login.bind(this)}>
        Se Connecter
      </button>

    const name = nickname ? nickname : "";

    const isAdmin = this.props.auth.scopes.split(" ").indexOf("read:panneaux") > -1 ? true: false;
    let filterButton;
    const button = isFilterOpen ?  <CancelBlueButton/> : <FilterButton/>;
    filterButton = <button onClick = {openFilter}>{button}</button>;

    const homeButton = isAdmin ?
      "":
      <button onClick = {eraseSelection}>
        <img className = "logoPA" src={img}/>
      </button>;

    const ConnectionListButton = userHasScopes(['read:connectionlist']) || userHasScopes(['read:panneaux']) ? (
      <button
        onClick = {this.goTo.bind(this, 'connection-list')}>
          <ConnectionList/>
      </button>
    ) : ""
    return (
      <div className = "nav">
        <div className = "nav--left">
          {filterButton}
          {homeButton}
        </div>
        <div className = "nav--right">
          <span> {name} </span>
          {Button}
          {ConnectionListButton}
        </div>
      </div>
    );
  }
}

export default Navbar;

import React, { Component } from 'react';
import allFields from '../data/billboards.json'
import {dateToReadableString} from '../lib/date'
const CLD_URL = 'https://res.cloudinary.com/dqi1btegb/image/upload';
import {DownloadIcon} from '../icons/pdficon.js'
import {WaitIcon} from '../icons/waiticon.js'

const fields = allFields.filter(field => field.showedToClient)
const fieldsNotShownedToDirecteur = [ "pdv", "fournisseur", "taxeMunicipale", "bailleurLoyer","dateDePaiement", "modeDePaiement", "bailPdf"]
class Row extends Component {
  constructor (props) {
    super(props);
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(){
    this.props.openMore(this.props.data._id)
  }

  render (){
    const {data, name} = this.props;
    let Icon = <DownloadIcon/>

    const tdData = fields.map(field => {
      if ( name === "Directeur" && fieldsNotShownedToDirecteur.indexOf(field.name)>-1) {
        return ("")
      }
      let cell = "";
      switch (field.type) {
        case "text":
          let text = data[field.name] ? data[field.name] : "";
          if(field.name === "bailPdf") {
            cell = data.bailPdf ? <a href={CLD_URL + "/"+data.bailPdf} target="_blank"><button className = "button-download center">{Icon}</button></a> : ""
          } else {
            cell = text
          }
          break;
        case "number":
          let n = data[field.name] ? data[field.name] : 0;
          if (field.format === "%") {
            cell = Math.round(n*100)/100 + "%"
          } else if (field.format === "€") {
            if (field.name === "pdv") {
              cell = n.toLocaleString('fr', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + " "+data["devise"]
            } else if (field.name === "bailleurLoyer" && n === 0){
              cell = ""
            } else if (field.name === "dpaNet") {
              cell = n.toLocaleString('fr', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + " "+data["deviseDPA"]
            } else {
              cell = n.toLocaleString('fr', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + " €"
            }
          } else {
            cell = n
          }
          break;
        case "date":
          cell = data[field.name] ? dateToReadableString(data[field.name]) : ""
          break;
        case "checkbox":
          cell = data[field.name] ? <div className = "square square-true"></div> : <div className = "square"></div>;
          break;
        default:
          cell = data[field.name]
      }
      if (field.name ==="client") {
        return false
      }
      return (<td key = {field.name} className = {field.cellFormat}>{cell}</td>)
    })

    return (
      <tr
        key = {data._id}
        onClick = {this.handleClick}
        className = "hoverified">
        <th
          className={"headcol th--client"}
          onClick = {this.handleSelect}
          id = {data._id}>
            {data.client}
        </th>
        {tdData}
      </tr>
    )
  }

}

const Headers = (props) => {
  const {sort, name} = props
  const thLabels = fields.map(field => {
    if (name === "Directeur" && fieldsNotShownedToDirecteur.indexOf(field.name)>-1) {
      return ("")
    }
    return (
      <th key = {"header"+field.name}>
        <SortButton
          sort = {sort}
          keyToSort = {field.name}
          text = {field.label}
        />
      </th>)
  })
  return (
    <tr>
      {thLabels}
    </tr>
  )
}

const TableauClient = (props) => {
  const {panneaux, sort, name} = props;
  const rows = panneaux.map(panneau => <Row key = {panneau._id} data = {panneau} openMore = {props.openMore} name = {name}/>);
  return (
    <div className = "panneaux panneaux--client">
      <table>
        <thead>
          <Headers sort = {sort} name = {name}/>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    </div>
  )
}

class SortButton extends Component {
  constructor (props) {
    super(props);
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(){
    this.props.sort(this.props.keyToSort)
  }

  render(){
    return (
      <button onClick = {this.handleClick}> {this.props.text} </button>
    )
  }
}
export default TableauClient

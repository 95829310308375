export function dialogWithDB(url, method, data, getAccessToken){
  const headers = {
    'Authorization': `Bearer ${getAccessToken()}`,
    "Content-Type": "application/json"
  }
  if (method === 'GET') {
    return fetch(url, {
      method: method,
      headers:headers
    })
      .then(results => {return results.json()})
  }
  return fetch(url, {
    method: method,
    body:JSON.stringify({data}),
    headers:headers
  })
    .then(results => {return results.json()})
}

import React, { Component } from 'react';
import {dateToReadableString} from '../lib/date'
import {pdfFichePose, getDataUri} from '../lib/pdf'
import {PdfIcon, UploadIcon} from '../icons/pdficon.js'
import {WaitIcon} from '../icons/waiticon.js'
import {DoneIcon} from '../icons/doneicon.js'
import {BackIcon} from '../icons/backicon.js'
import {dialogWithDB} from '../lib/communicationWithDB'
import { API_URL } from '../apiUrl.js';

const CLD_URL = 'https://res.cloudinary.com/dqi1btegb/image/upload';
const ROUTE = '/client/billboard';

let widgetPhoto;
const cldWidgetOpt = {
  cloudName: "dqi1btegb",
  uploadPreset: "x5pucfov",
  sources: ["local"],
  showAdvancedOptions: true,
  cropping: false,
  multiple: true,
  defaultSource: "local",
  styles: {
      palette: {
          window: "#FFFFFF",
          windowBorder: "#90A0B3",
          tabIcon: "#E00324",
          menuIcons: "#5A616A",
          textDark: "#000000",
          textLight: "#FFFFFF",
          link: "#E00324",
          action: "#FF620C",
          inactiveTabIcon: "#0E2F5A",
          error: "#F44235",
          inProgress: "#E00324",
          complete: "#20B832",
          sourceBg: "#E4EBF1"
      },
      fonts: {
          default: {
              active: true
          }
      }
  }
};
class FicheDePose extends Component {
  constructor (props) {
    super(props);
    this.state = {
      icon:"download",
      value:""
    }
    this.handleChange = this.handleChange.bind(this);
    this.makePdf = this.makePdf.bind(this);
    this.showWidget = this.showWidget.bind(this);
    this.checkUploadResultPhoto = this.checkUploadResultPhoto.bind(this);
    this.sendPhotoEmailRequest = this.sendPhotoEmailRequest.bind(this);
    this.sendWithoutPicture = this.sendWithoutPicture.bind(this);
  }

  componentDidMount(){
    widgetPhoto = window.cloudinary.createUploadWidget(cldWidgetOpt, (error, result) => { this.checkUploadResultPhoto(result)});
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  showWidget(){
    widgetPhoto.open()
  }

  checkUploadResultPhoto(resultEvent){
    if (resultEvent.event === "success") {
      this.sendPhotoEmailRequest(resultEvent.info.public_id)
    }
  }

  sendWithoutPicture() {
    this.sendPhotoEmailRequest('noPhoto')
  }

  sendPhotoEmailRequest(photoPublicId) {
    const billboardSelected = this.props.billboardSelected;
    const { getAccessToken } = this.props.auth;
    const email = this.props.auth.userProfile.name;
    const photoUrl = photoPublicId === "noPhoto" ? "Pas de photo" : `${CLD_URL}/${photoPublicId}`;
    const textToSend = this.state.value;
    dialogWithDB(`${API_URL}${ROUTE}/sendPhotoEmail/${email}`,'POST', {
      photoUrl,
      email,
      billboardSelected,
      textToSend
    }, getAccessToken)
      .then(response => {
        alert(response.message)
      })
      .catch(error => console.log(error) );
  }

  makePdf(){
    this.setState({icon:"wait"})
    if (this.props.logDownload) {
      this.props.logDownload(this.props.billboardSelected)
    }
    pdfFichePose(this.props.billboardSelected)
      .then(() => this.setState({icon:"done"}))
  }

  render() {
    const {photoSrc, format, reference, adresse, dateDepart, dateChangementVisuel, ville} = this.props.billboardSelected;
    const width = Math.min(document.body.clientWidth, 800);
    const imgs = photoSrc.map(photo => {
      // let photoJpg = photo.split(".").slice(-1).pop().join(".")+".jpg";
      // console.log(photoJpg);
      return (
        // <img src = {`${CLD_URL}/c_scale,w_${width}/${photo}`} key={photo}></img>
        <img src = {`${CLD_URL}/c_crop,g_south_west,h_2175,w_2501,y_60,pg_1/${photo}.jpg`} key={photo}></img>
      )}
    )
    let Icon = <PdfIcon/>
    switch (this.state.icon) {
      case "download":
        Icon = <PdfIcon/>
        break;
      case "wait":
        Icon = <WaitIcon/>
        break;
      case "done":
        Icon = <DoneIcon/>
        break;
      default:
        Icon = <PdfIcon/>
    };

    const pdfButton = photoSrc.length > 0 ?
        <a
          href = {`${CLD_URL}/${photoSrc[0]}`} target='_blank'><button
          className = "button-primary center"
          >{Icon}</button></a>
        : "" ;

    const dateChangementVisuelReadable = dateChangementVisuel ? dateToReadableString(dateChangementVisuel) : "non renseignée";
    const dateDepartReadable = dateDepart ? dateToReadableString(dateDepart) : "non renseignée";
    return (
      <div className = "container-fichepose">
        <button onClick = {this.props.eraseSelection} className = "button-secondary no-margin"><BackIcon/>Retour</button>
        <br/>
        {imgs}
        <h3>{ville}</h3>
        <p> <b>Format :</b> {format}</p>
        <p> <b>Adresse du panneau : </b>{adresse}</p>
        <p><b>Date de départ de contrat : </b>{dateDepartReadable}</p>
        {pdfButton}
        <hr/>
        <h4>Envoyer une photo du panneau : </h4>
        <div>
          <input
            name = "Text"
            type = "text"
            placeHolder = "Écrivez votre texte à envoyer ici"
            value={this.state.value}
            onChange={this.handleChange}
            //className = {red+" "+grey}
          ></input>
          <button
            className = "button-primary center"
            onClick={this.sendWithoutPicture}
            key="sendMessageWithoutPicture">
              Envoyer un message sans photo 
          </button>
          <button
            className = "button-primary center"
            onClick={this.showWidget}
            key="uploadPhotoByClient">
              Choisir un pdf  <UploadIcon/>
          </button>
        </div>
        <button
          onClick = {this.props.eraseSelection}
          className = "button-secondary">
            Retour à la carte ou au tableau
        </button>
      </div>
    )
  }
}




export default FicheDePose

import React from 'react'
import './inputs.scss'
import {dateForForm} from '../lib/date'

const Select = (props) => {
  const {type, name, required, options} = props.inputdata;
  let red = props.isRed ? "red":"";
  let grey = required ? "" : "grey";
  const Options = options.map(option => {
    return (
      <option key={"option"+option} value={option}>{option}</option>
    )
  })
  return (
    <div className = "container-input" onClick = {((e) => document.querySelector("select[name="+name+"]").focus())}>
      <select
        name = {name}
        value={props.value}
        onChange = {props.handleChange}
        className = {red+" "+grey}
        >
        {Options}
      </select>
      <label className = "small">{props.label}</label>
    </div>
  )
}


const Checkbox = (props) => {
  const {type, name, required, disabled} = props.inputdata;
  const classAdded = props.value ? "container-checkbox--blue" : "";
  return (
    <div className = {"container-checkbox "+classAdded} >
      <label>{props.label}</label>
      <input
        name = {name}
        type = "checkbox"
        checked = {props.value}
        onChange = {props.handleChange}
        disabled = {disabled}
      ></input>
    </div>
  )
}

const InputText = (props) => {
  const {type, name, required, disabled} = props.inputdata;
  let value = props.value;
  let classToAdd = ""
  if (type === "number" || value || type === "date") {
    classToAdd = "small"
  }
  // if (name === "pdv" || name === "dpaNet") {
  //   value = value.toLocaleString('fr', {minimumFractionDigits: 2, maximumFractionDigits: 2})
  // }
  let red = props.isRed ? "red":"";
  let grey = required ? "" : "grey";

  return (
    <div className = "container-input" onClick = {((e) => document.querySelector("input[name="+name+"]").focus())}>
      <input
        name = {name}
        type = "text"
        required = {required}
        value = {value || ''}
        onChange = {props.handleChange}
        disabled = {disabled}
        required = {required}
        className = {red+" "+grey}
      ></input>
      <label className = {classToAdd}>{props.label}</label>
    </div>
  )
}

const InputDate = (props) => {
  const {type, name, required, disabled} = props.inputdata;
  let value = props.value;
  let classToAdd = "small"
  let red = props.isRed ? "red":"";
  let grey = required ? "" : "grey";
  value = value ? value.substr(0,10) : value;
  return (
    <div className = "container-input" onClick = {((e) => document.querySelector("input[name="+name+"]").focus())}>
      <input
        name = {name}
        type = {type}
        required = {required}
        value = {value || ''}
        onChange = {props.handleChange}
        disabled = {disabled}
        required = {required}
        className = {red+" "+grey}
      ></input>
      <label className = {classToAdd}>{props.label}</label>
    </div>
  )
}

const Textarea = (props) => {
  const {type, name, required, disabled} = props.inputdata;
  const value = props.value;
  let classToAdd = ""
  if (type === "number" || value) {
    classToAdd = "small"
  }
  let red = props.isRed ? "red":"";
  let grey = required ? "" : "grey";
  return (
    <div className = "container-input" onClick = {((e) => document.querySelector("textarea[name="+name+"]").focus())}>
      <textarea
        name = {name}
        type = {type}
        required = {required}
        value = {value || ''}
        onChange = {props.handleChange}
        disabled = {disabled}
        rows="7"
        className = {red+" "+grey}
      ></textarea>
      <label className = {classToAdd}>{props.label}</label>
    </div>
  )
}

const Input = (props) => {
  if (props.inputdata.hide) {
    return ("")
  }
  switch (props.inputdata.type) {
    case "select" :
      return (
        <Select
          inputdata = {props.inputdata}
          label = {props.label}
          handleChange = {props.handleChange}
          value = {props.value}
          isRed = {props.isRed}
        />
      )
    case "text" :
    case "number" :
      return (
        <InputText
          inputdata = {props.inputdata}
          label = {props.label}
          handleChange = {props.handleChange}
          value = {props.value}
          isRed = {props.isRed}
        />
      )
      break;
    case "date" :
      return (
        <InputDate
          inputdata = {props.inputdata}
          label = {props.label}
          handleChange = {props.handleChange}
          value = {props.value}
          isRed = {props.isRed}
        />
      )
      break;

    case "checkbox" :
      return (
        <Checkbox
          inputdata = {props.inputdata}
          label = {props.label}
          handleChange = {props.handleChange}
          value = {props.value}
        />
      )
      break;

    case "textarea" :
      return (
        <Textarea
          inputdata = {props.inputdata}
          label = {props.label}
          handleChange = {props.handleChange}
          value = {props.value}
          isRed = {props.isRed}
        />
      )
      break;

    default:
      return (
        <InputText
          inputdata = {props.inputdata}
          label = {props.label}
          handleChange = {props.handleChange}
          value = {props.value}
          isRed = {props.isRed}
        />
      )
  }
}

export default Input;

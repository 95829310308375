import React, { Component } from 'react';
import './toolbarChoice.scss';
import {ExportButton, FolderButton, CancelButton} from '../Buttons/RoundButtons'
import {Download} from '../lib/csvDownload'

const ToolbarChoice = (props) => {
  const { isAuthenticated, userHasScopes } = props.auth;
  const red = {
    color: "#E00324"
  }
  const styleCarte = props.isMapShowed ? red : {};
  const styleTableau = props.isMapShowed ? {} :red ;
  const archiveButton = props.showArchive ? <ArchiveButton showArchive = {props.showArchive} state = {props.state}/> : "";
  return (
    <div className = "toolbar">
      <Download data={props.data} fields = {props.fields} profile = {props.profile}/>
      {archiveButton }
      <div className = "toolbar--choice">
        <span style = {styleCarte}>Carte</span>
        <ToggleButton isLeft = {props.isMapShowed} toggleView = {props.toggleView}/>
        <span style = {styleTableau}>Tableau</span>
      </div>
    </div>
  )
}

const ToggleButton = (props) => {
  const left = props.isLeft ? "0px" : "20px";
  return (
    <div className = "toggle-button" onClick = {props.toggleView}>
      <div className = "toggle-button__circle" style = {{left : left}}></div>
    </div>
  )
}

const ArchiveButton = (props) => {
  switch (props.state) {
    case "all":
      return (
        <button onClick = {(e) => props.showArchive(e, "archive")}>
          <FolderButton/>
          <span>Archives</span>
        </button>
      )
      break;
    case "archive":
      return (
        <div className = "toolbar__button-container">
          <span><b>Archives</b></span>
          <button onClick = {(e) => props.showArchive(e, "all")}>
            <CancelButton/><span>Retour</span>
          </button>
        </div>
      )
      break;
    default:

  }

}
export default ToolbarChoice

import {dateToReadableString, dateForForm} from './date'
const CLD_URL = 'https://res.cloudinary.com/dqi1btegb/image/upload';
import React, { Component } from 'react';
import {ExportButton} from '../Buttons/RoundButtons'

import * as XLSX from 'xlsx';

export class Download extends Component {
  constructor (props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    let {data, fields, profile} = this.props;
    const arrFieldsName = fields.map(item => item.name);
    const dataToDownload = data.map(item => {
      const newItem = {};
      arrFieldsName.forEach((field, i) => {
        if(field === "dateDepart" || field === "dateRenouvellement" || field === "dateChangementVisuel") {
          newItem[field] = item[field] ? dateToReadableString(item[field]) : ""
        } else {
          newItem[field] = item[field]
        }
      });
      return newItem
    })
    const headers = fields.map(field => {
      return (field.label)
    })

    /* make the worksheet */
    const ws = XLSX.utils.json_to_sheet([{}], { header: headers });
    XLSX.utils.sheet_add_json(ws, dataToDownload, { origin: 'A2', skipHeader: true });

    /* add to workbook */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Panneaux");

    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "CAPGestion.xlsx");
  }
  render() {
    return (
      <div>
        <button onClick={this.handleClick}><ExportButton/></button>
      </div>
    )
  }
}
 

// ---- OLD WITH ReactExport FOR EXCEL ------
// import ReactExport from "react-export-excel";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// export class Download extends React.Component {
//     constructor (props) {
//       super(props);
//       this.handleClick = this.handleClick.bind(this);
//     }
//     handleClick() {
//       alert("Ooops, il y a eu un problème dans le téléchargement de vos données, merci de contacter service@kreature.fr. ");
//     }
//     render() {
//       let {data, fields, profile} = this.props;
//       let userName = profile.name ? profile.name : " ";
//       let isCarrefour = userName.includes("carrefour");
//       const arrFieldsName = fields.map(item => item.name);
//       const dataToShow = data.map(item => {
//         const newItem = {};
//         arrFieldsName.forEach((field, i) => {
//           if(field === "dateDepart" || field === "dateRenouvellement" || field === "dateChangementVisuel") {
//             newItem[field] = item[field] ? dateToReadableString(item[field]) : ""
//           } else {
//             newItem[field] = item[field]
//           }
//         });
//         return newItem
//       })
//       const columns = fields.map(field => {
//         return (<ExcelColumn label={field.label} value={field.name} key={field.name}/>)
//       })
//       let renderDownloadButton = () => {
//         if(isCarrefour){
//           return <button onClick={this.handleClick}><ExportButton/></button>
//         }else{
//           return <ExcelFile element={<button><ExportButton/></button>}>
//               <ExcelSheet data={dataToShow} name="CAPGestion">
//                   {columns}
//               </ExcelSheet>
//           </ExcelFile>
//         }
//       }
//       return (
//           <div>
//             {renderDownloadButton()}
//           </div>
//       );
//     }
// }

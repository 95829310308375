import React, { Component } from 'react';
import './filter.scss'
import categories from './filter.json';

class Filter extends Component {
  constructor (props) {
    super(props);
    this.state = {
      lists : {},
      choicesShowed : []
    }
    this.showChoice = this.showChoice.bind(this)
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.panneaux.length  !== this.props.panneaux.length) {
      const {panneaux} = this.props;
      const categoriesName = categories.map(category => category.name);
      let lists = {};
      categoriesName.map(categoryName => {
        let list = [];
        const allItems = panneaux.map(panneau => {
          if (list.indexOf(panneau[categoryName])===-1) {
            list.push(panneau[categoryName])
          }
        });
        const listSorted = list.sort(function(x,y) {
            return x < y ? -1 : x > y ? 1 : 0;
        })
        lists[categoryName] = listSorted;
      })
      this.setState({lists})
    }
  }

  showChoice(e, categoryName){
    let choicesShowed = this.state.choicesShowed;
    if (choicesShowed.indexOf(categoryName) > -1) {
      choicesShowed = choicesShowed.filter(item => item !== categoryName)
    } else {
      choicesShowed.push(categoryName)
    }
    this.setState({choicesShowed})
  }

  render(){
    const leftPosition = this.props.isFilterOpen ? "0px" : "100%";
    const divStyle = {
      left : leftPosition
    }
    const categoriesAsked = Object.keys(this.props.filters);
    const Categories = categories.map(category => {
      const Choices = this.state.lists[category.name] ?  this.state.lists[category.name].map(item => <CheckBoxFilter label = {item} key={item} category = {category.name} filter = {this.props.filter}/>) : "";
      const isChoicesShowed = this.state.choicesShowed.indexOf(category.name) > -1
      const isDisplayed = isChoicesShowed ? "block" : "none";
      const plusOrMinus = isChoicesShowed ? "-" : "+";
      if (categoriesAsked.indexOf(category.name)>-1){
        return (
          <div key={category.name}>
            <p className = "filter__choice" onClick = {(e) => this.showChoice(e, category.name)}>{category.title}  {plusOrMinus} </p>
            <div style = {{display : isDisplayed}}>
              {Choices}
            </div>
          </div>
        )
      } else {
        return ("")
      }
      })

    return (
      <div style = {divStyle} className = "filter">
        <h3>Filtrer par : </h3>
        {Categories}
      </div>
    )
  }
}

class CheckBoxFilter extends Component {
  constructor (props) {
    super(props);
    this.state = {
      checked : false
    }
    this.handleCheck = this.handleCheck.bind(this)
  }

  handleCheck() {
    this.props.filter(this.props.category, this.props.label)
  }

  render(){
    return (
      <div>
        <input
          type = "checkbox"
          className = "checkbox-filter"
          onChange={this.handleCheck}
          defaultChecked={this.state.checked}
        ></input>
        <label>{this.props.label}</label>
      </div>
    )
  }

}
export default Filter;

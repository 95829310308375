import React, { Component } from 'react';
import Navbar from '../Navbar';
import ToolbarChoice from '../commons/ToolbarChoice';
import TableauClient from './TableauClient';
import MultipleMarkers from '../commons/Map';
import FicheDePose from '../commons/FicheDePose';
import {sortByKey} from '../lib/sort'
import Filter from '../commons/Filter';
import fields from '../data/billboards.json'

import {dialogWithDB} from '../lib/communicationWithDB'
import './client.scss'
import { API_URL } from '../apiUrl.js';

const ROUTE = '/client/billboard';

class Client extends Component {
  constructor (props) {
    super(props);
    this.state = {
      profile: {},
      isMapShowed : false,
      allPanneaux : [],
      panneaux: [],
      shops: [],
      billboardSelected :{},
      isFilterOpen: false,
      state:"all",
      filters: {
        client:[],
        ville:[],
        fournisseur:[]
      },
      img:"",
      name:""
    }
    this.openFilter = this.openFilter.bind(this)
    this.filter = this.filter.bind(this)
    this.getPanneaux = this.getPanneaux.bind(this)
    this.getShops = this.getShops.bind(this)
    this.toggleView = this.toggleView.bind(this)
    this.openMore = this.openMore.bind(this)
    this.eraseSelection = this.eraseSelection.bind(this)
    this.logDownload = this.logDownload.bind(this)
    this.sort = this.sort.bind(this)
    this.changeStateTo = this.changeStateTo.bind(this)

  }

  componentDidMount() {
    const { userProfile, getProfile } = this.props.auth;

    if (!userProfile) {
      getProfile((err, profile) => {
        this.setState({ profile });
        this.getPanneaux(profile.name)
        this.getShops()
      });
    } else {
      this.setState({ profile: userProfile });
      this.getPanneaux(userProfile.name)
      this.getShops()
    }
  }

  changeStateTo(e, state){
    const { getAccessToken, getProfile } = this.props.auth;
    const email = this.state.profile.name;
    let newState = state;
    switch (state) {
      case "all":
        this.setState({
          panneaux: this.state.allPanneaux
        })
        break;
      case "archive":
        dialogWithDB(`${API_URL}${ROUTE}/archived/${email}`,'GET', "", getAccessToken)
          .then(response => {
            this.setState({
              panneaux: response.panneaux
            })
          })
          .catch(error => alert(error.message) );
        break;
    }

    this.setState({state: newState})
  }

  filter(key, value){
    let filters = this.state.filters;
    const index = filters[key].indexOf(value);
    if(index === -1) {
      filters[key].push(value);
    } else {
      filters[key].splice(index,1)
    }
    this.setState({filters});
    let panneaux = this.state.allPanneaux;
    Object.keys(filters).map(key => {
      if(filters[key].length>0){
        panneaux = panneaux.filter(panneau => (filters[key].indexOf(panneau[key])>-1))
      }
    })
    this.setState({panneaux})
  }

  openFilter(){
    this.setState(prevState => ({isFilterOpen : !prevState.isFilterOpen}))
  }
  logDownload(billboard){
    const name = this.state.profile.name;
    const { getAccessToken } = this.props.auth;
    const { reference, ville, adresse} = billboard;
    dialogWithDB(`${API_URL}${ROUTE}/logDownload/${name}`,'POST', {reference, ville, adresse}, getAccessToken)
      .catch(error => console.error("couldn't log Download"));
  }

  getPanneaux(email){
    const { getAccessToken } = this.props.auth;
    dialogWithDB(`${API_URL}${ROUTE}/${email}`,'GET', "", getAccessToken)
      .then(response => {
        let panneauxSorted = sortByKey(response.panneaux, "client")
        this.setState({
          allPanneaux: response.panneaux,
          panneaux: response.panneaux,
          img: response.img,
          name: response.name,
          isMapShowed: true
        })
      })
      .catch(error => alert(error.message) );
  }

  getShops() {
    const { getAccessToken } = this.props.auth;
    dialogWithDB(`${API_URL}/client/shop`,'GET', "", getAccessToken)
      .then((response) => {
        this.setState({shops: response.shops})
      })
      .catch((error) => {
        console.log(error)
        alert(error.message)
      });
  }

  toggleView() {
    this.setState(prevState => ({
      isMapShowed: !prevState.isMapShowed
    }));
  }

  eraseSelection(){
    this.setState({billboardSelected : {}})
  }

  openMore(id) {
    const billboardSelected = this.state.panneaux.filter(panneau => panneau._id === id)[0];
    this.setState({billboardSelected})
  }

  sort(key){
    let panneauxSorted = sortByKey(this.state.panneaux.slice(0), key);
    this.setState({panneaux: panneauxSorted})
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    if(isAuthenticated) {
      history.pushState(null, null, location.href);
      window.onpopstate = function(event) {
        history.go(1);
      };
    }
    const { panneaux, profile, isMapShowed, billboardSelected, img } = this.state;
    const markers = panneaux.map(panneau => {
      const latitude = panneau.gps.split(",")[0];
      const longitude = panneau.gps.split(",")[1];
      return {
        data : panneau,
        position : [latitude, longitude]
      }
    })
    const styleContainerMap = {
      "height" : window.innerHeight-120
    }
    const fieldsForClients = fields.filter(field => field.showedToClient)

    return (
      <div>
        <Navbar
          auth = {this.props.auth}
          nickname = {profile.nickname}
          img = {img ? img : "https://res.cloudinary.com/dqi1btegb/image/upload/v1566288144/clients/logoPA_opi3ys.png"}
          eraseSelection = {this.eraseSelection}
          openFilter = {this.openFilter}
          isFilterOpen = {this.state.isFilterOpen}
          history={this.props.history}
          />

        <ToolbarChoice
          auth = {this.props.auth}
          toggleView = {this.toggleView}
          isMapShowed = {this.state.isMapShowed}
          data={panneaux}
          fields = {fieldsForClients}
          profile = {profile}
          showArchive = {this.changeStateTo}
          state = {this.state.state}
          />

        <Filter
          isFilterOpen = {this.state.isFilterOpen}
          panneaux = {this.state.allPanneaux}
          filter = {this.filter}
          filters = {this.state.filters}/>

        <div className="container" style = {styleContainerMap}>
          <Content
            markers={markers}
            shops = {this.state.shops}
            panneaux = {panneaux}
            openMore = {this.openMore}
            isMapShowed = {isMapShowed}
            billboardSelected = {billboardSelected}
            eraseSelection = {this.eraseSelection}
            logDownload = {this.logDownload}
            sort = {this.sort}
            name = {this.state.name}
            auth = {this.props.auth}
            />
        </div>
      </div>
    );
  }
}

const Content = (props) => {
  const {
    name,
    markers,
    shops,
    panneaux,
    openMore,
    isMapShowed,
    billboardSelected,
    eraseSelection,
    logDownload,
    sort,
    auth
  } = props;

  if (billboardSelected._id){
    return (
      <FicheDePose
        billboardSelected = {billboardSelected}
        eraseSelection = {eraseSelection}
        logDownload = {logDownload}
        auth = {auth}
        />
    )
  } else if (isMapShowed) {
    return (
      <MultipleMarkers
        markers={markers}
        shops = {shops}
        openMore = {openMore}
        showMagasinsProvencia = {true}
        showMagasinsSoBio={true}
        showMagasinsBrutButcher = {true}
        />
    )
  } else {
    return (
      <TableauClient
        panneaux = {panneaux}
        openMore = {openMore}
        sort = {sort}
        name = {name}
        />
    )
  }
}

export default Client;

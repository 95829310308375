import React from 'react'

export const BackIcon = (props) => {
  return (
    <svg id="backbutton" viewBox="0 0 33 20" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <g  transform="translate(-14.000000, -20.000000)" fill="#585858" fillRule="nonzero">
          <g id="logout-outline" transform="translate(30.500000, 30.000000) scale(-1, 1) translate(-30.500000, -30.000000) translate(14.000000, 20.000000)">
            <path d="M32.5362278,9.16773183 L17.422952,0.128780525 C17.2794322,0.0428627719 17.119306,0 16.9590871,0 C16.7987754,0 16.638742,0.0429588767 16.4952222,0.128780525 C16.2081826,0.300423822 16.0313573,0.617762102 16.0313573,0.961048696 L16.0313573,5.72227615 L0.927729844,5.72227615 C0.415344651,5.72227615 0,6.15263375 0,6.68332484 L0,13.3166752 C0,13.8474624 0.415344651,14.2777239 0.927729844,14.2777239 L16.0312645,14.2777239 L16.0312645,19.0389513 C16.0312645,19.3822379 16.2080898,19.6995762 16.4951294,19.8712195 C16.6386492,19.9572333 16.7986826,20 16.9589943,20 C17.1192133,20 17.2793394,19.9572333 17.4228592,19.8712195 L32.536135,10.8321721 C32.8231747,10.6605288 33,10.3431905 33,9.9999039 C33.0000927,9.65671341 32.8231747,9.33937513 32.5362278,9.16773183 Z M17.8867242,17.3745111 L17.8867242,13.3166752 C17.8867242,12.7860802 17.4712867,12.3556265 16.9589943,12.3556265 L1.85545969,12.3556265 L1.85545969,7.64446965 L16.9589943,7.64446965 C17.4712867,7.64446965 17.8867242,7.21420814 17.8867242,6.68342095 L17.8867242,2.62568114 L30.2168104,10 L17.8867242,17.3745111 Z"></path>
          </g>
        </g>
    </svg>
  )
}

import React from 'react'
import { COLORS } from '../colors-config';

const DeconnectButton = (props) => {
  return (
    <svg width="47px" height="30px" viewBox="0 0 47 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="deconnect"  fill={COLORS.secondary} fillRule="nonzero">
        <path d="M13.3842531,28.0124275 C6.58590052,27.6601785 1.34857834,21.3321219 1.87287076,13.995935 C2.36694909,7.08240744 7.89015712,1.76198809 14.3443079,1.97864292 C18.5739123,2.12064699 22.2206329,4.57426046 24.2883232,8.15887722 C24.4467405,8.43351705 24.7187591,8.60885264 25.0192468,8.62443364 L25.0402773,8.62551839 C25.7712928,8.66338615 26.2429632,7.80524763 25.8581698,7.13674372 C23.50441,3.04623744 19.365907,0.231696949 14.557184,0.0134642973 C6.84689877,-0.336417966 0.289616019,6.17634255 0.00933217608,14.4578427 C-0.267737403,22.6410248 5.65844753,29.5867991 13.2958149,29.9823396 C18.5493002,30.2545141 23.2744524,27.3580254 25.8614759,22.8630048 C26.2269837,22.227931 25.8293333,21.4046032 25.1348685,21.3686091 L25.1140217,21.3675243 C24.7806566,21.3501683 24.468322,21.5325055 24.2926395,21.8372225 C22.044583,25.7364178 17.9437329,28.2487065 13.3842531,28.0124275 Z" ></path>
        <path d="M46.5362278,14.1677318 L31.422952,5.12878053 C31.2794322,5.04286277 31.119306,5 30.9590871,5 C30.7987754,5 30.638742,5.04295888 30.4952222,5.12878053 C30.2081826,5.30042382 30.0313573,5.6177621 30.0313573,5.9610487 L30.0313573,10.7222761 L14.9277298,10.7222761 C14.4153447,10.7222761 14,11.1526338 14,11.6833248 L14,18.3166752 C14,18.8474624 14.4153447,19.2777239 14.9277298,19.2777239 L30.0312645,19.2777239 L30.0312645,24.0389513 C30.0312645,24.3822379 30.2080898,24.6995762 30.4951294,24.8712195 C30.6386492,24.9572333 30.7986826,25 30.9589943,25 C31.1192133,25 31.2793394,24.9572333 31.4228592,24.8712195 L46.536135,15.8321721 C46.8231747,15.6605288 47,15.3431905 47,14.9999039 C47.0000927,14.6567134 46.8231747,14.3393751 46.5362278,14.1677318 Z M31.8867242,22.3745111 L31.8867242,18.3166752 C31.8867242,17.7860802 31.4712867,17.3556265 30.9589943,17.3556265 L15.8554597,17.3556265 L15.8554597,12.6444696 L30.9589943,12.6444696 C31.4712867,12.6444696 31.8867242,12.2142081 31.8867242,11.6834209 L31.8867242,7.62568114 L44.2168104,15 L31.8867242,22.3745111 Z"></path>
      </g>
    </svg>
  )
}
const ConnectionList = (props) => {
  return (
    <svg width="47px" height="30px" viewBox="0 0 47 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="connectionlist" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle stroke={COLORS.secondary} strokeWidth="2" cx="23" cy="15" r="14"></circle>
            <line x1="19.4333333" y1="9.5" x2="31.5666667" y2="9.5"  stroke={COLORS.secondary} strokeWidth="2" strokeLinecap="round"></line>
            <line x1="19.4333333" y1="13.5" x2="31.5666667" y2="13.5"  stroke={COLORS.secondary} strokeWidth="2" strokeLinecap="round"></line>
            <line x1="19.4333333" y1="17.5" x2="31.5666667" y2="17.5" stroke={COLORS.secondary} strokeWidth="2" strokeLinecap="round"></line>
            <line x1="19.4333333" y1="21.5" x2="31.5666667" y2="21.5"  stroke={COLORS.secondary} strokeWidth="2" strokeLinecap="round"></line>
            <line x1="15.0333333" y1="9.5" x2="16.0333333" y2="9.5"  stroke={COLORS.secondary} strokeWidth="2" strokeLinecap="round"></line>
            <line x1="15.0333333" y1="13.5" x2="16.0333333" y2="13.5"  stroke={COLORS.secondary} strokeWidth="2" strokeLinecap="round"></line>
            <line x1="15.0333333" y1="17.5" x2="16.0333333" y2="17.5"  stroke={COLORS.secondary} strokeWidth="2" strokeLinecap="round"></line>
            <line x1="15.0333333" y1="21.5" x2="16.0333333" y2="21.5"  stroke={COLORS.secondary} strokeWidth="2" strokeLinecap="round"></line>
        </g>
    </svg>
  )
}
export {DeconnectButton, ConnectionList}
